import React, { useState } from 'react';
import { TextField, Button, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function HeroSection({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <Box
      sx={{
        backgroundImage: isMobile ? 'none' : 'url(https://source.unsplash.com/random)', // Example background image from Unsplash
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '50px 0', // Adjust padding to control the height of the section
        display: 'flex',
        justifyContent: 'center', // Align items horizontally center
        alignItems: 'center', // Align items vertically center
        flexDirection: 'column', // Stack elements vertically on mobile
        gap: 2,
      }}
    >
      <TextField
        label="Rechercher une annonces..."
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          width: isMobile ? '90%' : '250px', // Full width on mobile
        }}
      />
      <Button
        variant="contained"
        onClick={handleSearch}
        sx={{ width: isMobile ? '90%' : 'auto' }} // Full width on mobile
      >
        Rechercher
      </Button>
    </Box>
  );
}

export default HeroSection;
