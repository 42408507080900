import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Container, Card, CardContent, Accordion, AccordionSummary, Grid, AccordionDetails, Typography, Badge, IconButton, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from './NavBar'; // Adjust import path as necessary
import MainChatView from './MainChatView'; // Adjust import path as necessary
import { useUser } from './UserContext';
import { useTheme } from '@mui/material/styles';

function PublisherMessages2() {
    const [adsAsPublisher, setAdsAsPublisher] = useState([]);
    const [adsAsClient, setAdsAsClient] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedAd, setSelectedAd] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { setTotalUnread } = useUser();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const fetchAds = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/legacy/adsdetails`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAdsAsPublisher(response.data.adsAsPublisher);
            console.log(response.data.adsAsClient)
            setAdsAsClient(response.data.adsAsClient);
            const totalUnread = response.data.adsAsPublisher.concat(response.data.adsAsClient).reduce((acc, ad) => {
                console.log('Ad:', ad.t);

                return Number(ad.total_unread) + totalUnread;
            }, 0);
            setTotalUnread(totalUnread);
        } catch (error) {
            console.error('Failed to fetch ads:', error);
        }
    };

    useEffect(() => {
        fetchAds();
    }, []);

    const handleChatSelection = async (chat, ad) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/messages/markasread`, {
                chatId: chat.chat_id
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchAds();
        } catch (error) {
            console.error('Failed to mark as read:', error);
        }

        setSelectedChat(chat);
        setSelectedAd(ad);
        if (isMobile) {
            setIsSidebarOpen(false);
        }
    };

    const handleBackToSidebar = () => {
        setIsSidebarOpen(true);
        setSelectedChat(null);
        setSelectedAd(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <NavBar ads={[...adsAsPublisher, ...adsAsClient]} />
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    {isSidebarOpen && (
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6">Comme annonceur</Typography>
                            {adsAsPublisher.map((ad) => (
                                <Accordion key={ad.ad_id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography id={`ad${ad.ad_id}`}>{ad.title}</Typography>
                                        <Badge badgeContent={ad.total_unread} color="secondary" sx={{ ml: 2 }} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {ad.chats.map((chat) => (
                                            <Typography id={`chat${ad.ad_id}`} key={chat.chat_id} onClick={() => handleChatSelection(chat, ad)}>
                                                Discussion avec {chat.other_person_alias} <br/> Non lu: <Badge badgeContent={chat.unread_count} color="secondary" sx={{ ml: 2 }} />
                                            </Typography>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}

                            <Typography variant="h6" sx={{ mt: 4 }}>Comme Client</Typography>
                            {adsAsClient.map((ad) => (
                                <Accordion key={ad.ad_id}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography id={`ad${ad.ad_id}`}>{ad.title}</Typography>
                                        <Badge badgeContent={ad.total_unread} color="secondary" sx={{ ml: 2 }} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {ad.chats.map((chat) => (
                                            <Typography id={`chat${ad.ad_id}`} key={chat.chat_id} onClick={() => handleChatSelection(chat, ad)}>
                                                Discussion avec {chat.other_person_alias} <br/> Non lu: <Badge badgeContent={chat.unread_count} color="secondary" sx={{ ml: 2 }} />
                                            </Typography>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Grid>
                    )}
                    {!isSidebarOpen && isMobile && (
                        <IconButton
                            color="inherit"
                            aria-label="open sidebar"
                            onClick={handleBackToSidebar}
                            sx={{ position: 'absolute', top: 64, left: 16 }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    <Grid item xs={12} md={8} sx={{ display: isSidebarOpen && isMobile ? 'none' : 'block' }}>
                        {selectedAd && (
                            <Card sx={{ maxWidth: '100%', mt: 4 }}>
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                        {selectedAd.title}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        {selectedAd.description}
                                    </Typography>
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2">Taille: {selectedAd.size_name}</Typography>
                                        <Typography variant="body2">Etat: {selectedAd.etat_name}</Typography>
                                        <Typography variant="body2">Publié le {new Date(selectedAd.created_at).toLocaleDateString()}</Typography>
                                        <Typography gutterBottom variant="h5">
                                            {selectedAd.price} €
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        )}
                        {selectedChat && <MainChatView selectedAd={selectedAd} selectedChat={selectedChat} />}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default PublisherMessages2;
