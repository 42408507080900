import React, { useState } from 'react';
import { useUser } from './UserContext';
import axios from 'axios';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Container, TextField, Button, Typography } from '@mui/material';
import NavBar from './NavBar'; // Adjust the path as necessary
import Footer from './Footer'; // Adjust the path as necessary

function Login({ onLogin }) {
    const { setUserId, setIsPublisher, setIsClient, setTotalUnread } = useUser();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
   
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(email, password)

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/login`, {
                email,
                password,
            });
            setUserId(response.data.userId);
            localStorage.setItem('token', response.data.token);
            //alert('Login successful!');
            setEmail('');
            setPassword('');
            navigate('/'); // Adjust as necessary for your homepage route
        } catch (error) {
            console.error('Login failed:', error.response ? error.response.data : error);
            alert('Login failed: Incorrect email or password.');
        }
    };

    return (
<div>
      <NavBar />
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">Se connecter</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mot de passe"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Se connecter
                </Button>
            </form>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
  <RouterLink to="/forgot-password">Mot de passe oublié ?</RouterLink> {/* Link to your forgot password page or dialog opener */}
</Typography>
        </Container>
        <Footer />
    </div>
    );
}

export default Login;
