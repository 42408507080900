import { jwtDecode } from 'jwt-decode';


const isTokenValid = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp * 1000 > Date.now(); // Check if the token is expired
    } catch (error) {
        return false; // Token is invalid if any error occurs
    }
};

export default isTokenValid;