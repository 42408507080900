import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavBar from './NavBar'; // Adjust the import path as necessary
import Footer from './Footer'; // Adjust the import path as necessary
import { Container, Typography, TextField, Button } from '@mui/material';

const UserAdmin = () => {
    const [userInfo, setUserInfo] = useState({ alias: '', profile: '' });
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Assume we have an endpoint to fetch user details
        const fetchUserInfo = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/users/details`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUserInfo(response.data);
        };
        fetchUserInfo();
    }, []);

    const handlePasswordChange = async () => {
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/change-password`, { newPassword }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            alert('Mot de passe mis a jour');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            setError('Failed to update password');
        }
    };

    const handleAliasChange = async (newAlias) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/change-alias`, { alias: newAlias }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUserInfo({ ...userInfo, alias: newAlias });
            alert('Alias successfully updated');
        } catch (error) {
            setError('Failed to update alias');
        }
    };

    return (
        <div>
            <NavBar />
            <Container maxWidth="sm">
                <Typography variant="h4" gutterBottom>Pages de paramétrages du compte</Typography>
                <Typography variant="h6">Profile: {userInfo.profile}</Typography>
                <Typography variant="h6">Pseudo: {userInfo.alias}</Typography>
                
                <TextField
                    label="Nouveau Pseudo"
                    variant="outlined"
                    name="pseudo"
                    fullWidth
                    value={userInfo.alias}
                    onChange={(e) => setUserInfo({ ...userInfo, alias: e.target.value })}
                    margin="normal"
                />
                <Button id="PseudoButton" onClick={() => handleAliasChange(userInfo.alias)} variant="contained">Changer le pseudo</Button>
                
                <TextField
                    label="Nouveau mot de passe"
                    type="password"
                    name="oldpassword"
                    variant="outlined"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    margin="normal"
                />
                <TextField
                    label="Confirmer le mot de passe"
                    type="password"
                    name="newpassword"
                    variant="outlined"
                    fullWidth
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    margin="normal"
                />
                <Button id="PasswordButton" onClick={handlePasswordChange} variant="contained">Changer le mot de passe</Button>
                {error && <Typography color="error">{error}</Typography>}
            </Container>
            <Footer />
        </div>
    );
};

export default UserAdmin;
