import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
// Create the context
const UserContext = createContext();

// Provider component
export const UserProvider = ({ children }) => {
    const [totalUnread, setTotalUnread] = useState(0);
    const [userId, setUserId] = useState(null);
    const [isPublisher, setIsPublisher] = useState(false);
    const [isClient, setIsClient] = useState(false);

    // Example of setting these values based on user data fetched from an API
    useEffect(() => {
        // Placeholder for where you might fetch user data
        const token = localStorage.getItem('token');
        const fetchUserData = async () => {
            try {
                // Your API call here
                const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/unread/user`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                // Assuming the API returns a JSON object that includes a count property
                setTotalUnread(response.data.count); // Ensure this matches the actual API response structure
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                // Set total unread messages to 0 in case of any error
                setTotalUnread(0);
            }
        };

        fetchUserData();
    }, []);

    return (
        <UserContext.Provider value={{
            totalUnread, setTotalUnread,
            userId, setUserId,
            isPublisher, setIsPublisher,
            isClient, setIsClient
        }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the context
export const useUser = () => useContext(UserContext);
