import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, List, ListItem, ListItemText } from '@mui/material';
import NavBar from './NavBar';
import Footer from './Footer';

function Admin() {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        
        axios.get(`${process.env.REACT_APP_API_DNS}/api/admin/categories`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
        .then(response => setCategories(response.data))
        .catch(error => {
            if (error.response.status === 403 || error.response.status === 401) {
                navigate('/login');
            }
        });
    }, [navigate]);

    const handleAddCategory = () => {
        axios.post(`${process.env.REACT_APP_API_DNS}/api/admin/categories`, { name: newCategory }, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
        .then(response => {
            setCategories([...categories, response.data]);
            setNewCategory('');
        })
        .catch(error => console.error('Error adding category:', error));
    };

    return (
        <div>
            <NavBar />
            <h1>Gerer les catégories</h1>
            <TextField
                label="Nouvelle catégorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
            />
            <Button onClick={handleAddCategory}>Ajouter une catégorie</Button>
            <List>
                {categories.map((category, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={category.name} />
                    </ListItem>
                ))}
            </List>
            <Footer />
        </div>
    );
}

export default Admin;
