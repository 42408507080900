import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Link, List, ListItem, ListItemText } from '@mui/material';
import {jwtDecode }from 'jwt-decode'; // Corrected import

function MainChatView({ selectedAd, selectedChat }) {
    const [currentUserId, setCurrentUserId] = useState(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]); // for the chat history
    const [adDetails, setAdDetails] = useState(null);

    useEffect(() => {
        const fetchMessagesAndAdDetails = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                setCurrentUserId(decodedToken.userId);
                console.log(decodedToken.userId);
            }

            try {
                const messagesResponse = await axios.get(`${process.env.REACT_APP_API_DNS}/api/admin/messages/${selectedChat.chat_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const modifiedMessages = messagesResponse.data.messages.map(message => ({
                    ...message,
                    writterId: message.ispublisher ? message.publisher_id : message.client_id,
                }));
                setMessages(modifiedMessages);
                console.log("mes messages:", modifiedMessages);

   

                const adResponse = await axios.get(`${process.env.REACT_APP_API_DNS}/api/ads/${selectedAd.ad_id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setAdDetails(adResponse.data.ad);
            } catch (error) {
                console.error('Failed to fetch messages and ad details:', error);
            }
        };

        fetchMessagesAndAdDetails();
    }, [selectedChat, selectedAd]);

    const sendMessage = async () => {
        if (!message) return;
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/messages`, { chat_id: selectedChat.chat_id, content: message }, { headers: { Authorization: `Bearer ${token}` } });
            setMessage('');
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/chats/${selectedChat.chat_id}`);
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };


    return (
        <>
            <Box sx={{ p: 3, width: '100%' }}>
                {adDetails && (
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">{adDetails.title}</Typography>
                        <Typography variant="body1">{adDetails.description}</Typography>
                        <Link href={`/AdDetails/${adDetails.id}`} target="_blank">Detail de la fiche produit</Link>
                    </Box>
                )}
                <List sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
                    {messages.map((msg) => (
                        <ListItem
                            key={msg.id}
                            sx={{
                                alignSelf: msg.writterId === currentUserId ? 'flex-end' : 'flex-start',
                                bgcolor: msg.writterId === currentUserId ? '#e0e0e0' : '#f0f0f0',
                                p: 1,
                                borderRadius: 2,
                                mb: 1,
                                maxWidth: '75%',
                                boxShadow: 1,
                            }}
                        >
                            <ListItemText
                                primary={msg.content}
                                secondary={`${ msg.writterId === currentUserId ? 'envoyé' : 'reçu'}: ${new Date(msg.created_at).toLocaleString()}`}
                                sx={{ textAlign:  msg.writterId === currentUserId ? 'right' : 'left', maxWidth: '100%' }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <TextField
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here..."
                variant="outlined"
                sx={{ mt: 1 }}
            />
            <Button variant="contained" color="primary" onClick={sendMessage} sx={{ mt: 1 }}>
                Envoyer
            </Button>
        </>
    );
}

export default MainChatView;
