import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  AppBar, IconButton, Toolbar, Button, Typography, Badge, Drawer, List, ListItem, ListItemText, useMediaQuery, Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme } from '@mui/material/styles';
import logo from '../assets/apellogo.png';
import isTokenValid from './isTokenValid';
import { useUser } from './UserContext';

function NavBar() {
  const { totalUnread, setTotalUnread } = useUser();
  const [unreadUserMessages, setUnreadUserMessages] = useState(0);
  const [unreadPublisherMessages, setUnreadPublisherMessages] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, user might not be logged in');
      return;
    }

    axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/unread/user`, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(response => {
      setUnreadUserMessages(response.data.count);
      setTotalUnread(response.data.count);
    }).catch(error => {
      console.error('Failed to fetch unread user messages:', error);
    });
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const menuItems = isTokenValid() ? (
    <>
      <ListItem button id="BadgeButton" component={RouterLink} to="/publisher-messages2">
        <Badge badgeContent={totalUnread} color="secondary">
          <MailIcon />
        </Badge>
        <ListItemText primary="Messages" />
      </ListItem>
      <ListItem button id="PublierUneAnnonce" component={RouterLink} to="/create-ad">
        <ListItemText primary="Publier une Annonce" />
      </ListItem>
      <ListItem button id="Disconnect" onClick={handleLogout}>
        <ListItemText primary="Se déconnecter" />
      </ListItem>
      <ListItem button id="SettingButton" component={RouterLink} to="/useradmin">
        <SettingsIcon />
        <ListItemText primary="Mon compte" />
      </ListItem>
    </>
  ) : (
    <>
      <ListItem button component={RouterLink} to="/login">
        <ListItemText primary="Se connecter" />
      </ListItem>
      <ListItem button component={RouterLink} to="/register">
        <ListItemText primary="S'enregistrer" />
      </ListItem>
    </>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton color="inherit" component={RouterLink} to="/" sx={{ flexGrow: 1 }}>
          <img src={logo} alt="Logo" style={{ marginRight: '10px', height: '50px' }} />
        </IconButton>
        {!isMobile && (
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Petits Annonces de l APEL de Saint Georges - Salbris
          </Typography>
        )}
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              onClick={toggleDrawer(true)}
              sx={{
                border: '1px solid white',
                borderRadius: '4px',
                marginLeft: 'auto'
              }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="top"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{ style: { width: 'auto', right: 0, left: 'auto' } }}
            >
              <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                {menuItems}
              </List>
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {menuItems}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
