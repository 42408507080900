import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import HeroSection from './HeroSection';
import AdList from './AdList';
import FilterSidebar from './FilterSidebar';
import axios from 'axios';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Homepage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ads, setAds] = useState([]);
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    category_id: '',
    priceRange: { min: 0, max: 1000 },
    size_id: '',
    etat_id: '',
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const validateToken = async () => {
      console.log("in validate token")
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/users/validatetoken`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setIsAuthenticated(response.data.isValid);
          if (!response.data.isValid) {
           // localStorage.removeItem('token'); // Clear invalid token
           // navigate('/login');
          }
        } catch (error) {
          console.error('Token validation failed:', error);
         // localStorage.removeItem('token'); // Clear invalid token
         // navigate('/login');
        }
      }
    };

    axios.get(`${process.env.REACT_APP_API_DNS}/api/ads/latest`).then(response => {
      setAds(response.data);
    }).catch(error => {
      console.error('Failed to fetch ads:', error);
    });
    validateToken();
  }, [navigate]);

  const fetchAds = (searchTerm = '', updatedFilters = filters) => {
    // Construct query parameters based on filters and searchTerm
    let queryParams = `?search=${searchTerm}`;
    if (updatedFilters.category_id) queryParams += `&category_id=${updatedFilters.category_id}`;
    if (updatedFilters.size_id) queryParams += `&size_id=${updatedFilters.size_id}`;
    if (updatedFilters.etat_id) queryParams += `&etat_id=${updatedFilters.etat_id}`;
    if (updatedFilters.priceRange.min !== undefined) queryParams += `&minPrice=${updatedFilters.priceRange.min}`;
    if (updatedFilters.priceRange.max !== undefined) queryParams += `&maxPrice=${updatedFilters.priceRange.max}`;

    axios.get(`${process.env.REACT_APP_API_DNS}/api/ads${queryParams}`).then((response) => {
        setAds(response.data);
        console.log(response.data)
    });
  };

  const handleSearch = (searchTerm) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    fetchAds(searchTerm);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    fetchAds('', newFilters);
  };

  return (
    <div>
      <NavBar/>
        <HeroSection onSearch={handleSearch} />
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
          {isAuthenticated && <FilterSidebar onFilterChange={handleFilterChange} /> }
          <AdList ads={ads} />
        </Box>
      <Footer />
    </div>
  );
}

export default Homepage;
