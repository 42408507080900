// ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Typography } from '@mui/material';
import NavBar from './NavBar';  // Adjust import path as necessary
import Footer from './Footer';  // Adjust import path as necessary

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/forgot-password`, { email });
            alert('Vous avez un recu un code d activation par email.');
        } catch (error) {
            console.error('Erreur pendant la réinitialisation du mot de passe:', error);
            alert('Erreur pendant la réinitialisation du mot de passe.');
        }
    };

    return (
        <>
        <NavBar />
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">Mot de passe oublié</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Envoyer un code re réinitialisation
                </Button>
            </form>
        </Container>
        <Footer />
        </>
    );
}

export default ForgotPassword;
