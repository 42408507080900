import React from 'react';
import { Box, Typography, Link, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite'; // Import the Favorite icon for the heart

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', padding: '2rem 0' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction={isMobile ? 'column' : 'row'}>
        <Grid item>
          <Typography variant="h6" textAlign={isMobile ? 'center' : 'left'}>
            Petits Annonces de l APEL de Saint Georges - Salbris
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box textAlign="center">
            <Link href="/terms" underline="hover" sx={{ margin: '0 1rem' }}>
              Conditions d'utilisation / Données personnelles
            </Link>
            <a href="mailto:apelsaintgeorgessalbris@hotmail.com?subject=Siteweb apel petites annonces Saint Georges" style={{ textDecoration: 'none', color: 'inherit' }}>
              Contactez Nous
            </a>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary" align="center">
            © {new Date().getFullYear()} Petits Annonces de l APEL de Saint Georges - Salbris. All rights reserved.
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
            Hébergé avec <FavoriteIcon sx={{ verticalAlign: 'middle', color: 'red' }} /> en Sologne
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
