import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography } from '@mui/material';
import NavBar from './NavBar';  // Adjust import path as necessary
import Footer from './Footer';  // Adjust import path as necessary

function PasswordReset() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const { resetCode } = useParams(); // Using useParams to access the reset code from the URL

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("Les mots de passe sont différents!");
            return;
        }

        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/reset-password`, { resetCode, password });
            alert('Le mot de passe a été mis à jour');
            navigate('/login'); // Redirect to login page
        } catch (error) {
            console.error('Error resetting password:', error);
            alert('Failed to reset password.');
        }
    };

    return (
        <>
        <NavBar />
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">Reset Password</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Nouveau mot de passe"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirmer le mot de passe"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Changer le mot de passe
                </Button>
            </form>
        </Container>
        <Footer />
        </>
    );
}

export default PasswordReset;
