import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, MenuItem, Box, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Compressor from 'compressorjs';

import NavBar from './NavBar'; // Adjust the path as necessary
import Footer from './Footer'; // Adjust the path as necessary

function CreateAd() {
    const [adDetails, setAdDetails] = useState({
        title: '',
        category_id: '',
        etat_id: '',
        size_id: '',
        description: '',
        price: '',
        images: [] // This will be an array of Base64 encoded strings
    });
    const [categories, setCategories] = useState([]); // State to hold all categories
    const [etatCategories, setEtatCategories] = useState([]);
    const [tailleCategories, setTailleCategories] = useState([]);
    const [categorieCategories, setCategorieCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const navigate = useNavigate();
   
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/categories`);
                const categories = response.data;
                setCategories(categories);
          
                const etat = categories.filter(category => category.typology === 'etat');
                const taille = categories.filter(category => category.typology === 'taille');
                const categorie = categories.filter(category => category.typology === 'categorie');
          
                setEtatCategories(etat);
                setTailleCategories(taille);
                setCategorieCategories(categorie);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const validate = () => {
        const newErrors = {};
        if (!adDetails.title) newErrors.title = 'Title is required';
        if (!adDetails.category_id) newErrors.category_id = 'Category is required';
        if (!adDetails.etat_id) newErrors.etat_id = 'Etat is required';
        if (!adDetails.size_id) newErrors.size_id = 'Size is required';
        if (!adDetails.description) newErrors.description = 'Description is required';
        if (!adDetails.price) newErrors.price = 'Price is required';
        return newErrors;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = validate();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setSubmitError('Please fill in all required fields');
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found, user might not be logged in');
            return;
        }

        const adPayload = {
            ...adDetails,
            categoryName: categories.find(cat => cat.id === adDetails.category_id)?.name // Convert category_id to categoryName
        };
        //delete adPayload.category_id; // Remove category_id from the payload

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_DNS}/api/ads`, adPayload, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const { adId } = response.data;
            navigate(`/ads/${adId}`); // Redirect to the specific ad detail page
        } catch (error) {
            console.error('Failed to post ad:', error);
        }
    };

    const handleChange = (event) => {
        setAdDetails({ ...adDetails, [event.target.name]: event.target.value });
        setErrors({ ...errors, [event.target.name]: '' }); // Clear the error when user starts typing
        setSubmitError(''); // Clear the submit error message when user starts typing
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            new Compressor(file, {
                quality: 0.9, // Set the quality to 60% of the original
                maxWidth: 800, // Set the maximum width to 800px
                maxHeight: 600, // Set the maximum height to 600px
                success(result) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setAdDetails({ ...adDetails, images: [...adDetails.images, e.target.result] }); // Add the compressed and converted Base64 string to the images array
                    };
                    reader.readAsDataURL(result); // Convert the compressed image file to a Data URL
                },
                error(err) {
                    console.error('Compression Error:', err.message);
                },
            });
        }
    };

    const handleImageDelete = (index) => {
        const updatedImages = adDetails.images.filter((_, i) => i !== index);
        setAdDetails({ ...adDetails, images: updatedImages });
    };

    return (
        <>
            <NavBar />
            <Container component="main" maxWidth="md">
                <Typography component="h1" variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Post Your Ad
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        fullWidth
                        label="Titre de l'annonce"
                        name="title"
                        value={adDetails.title}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.title}
                        helperText={errors.title}
                        required
                    />
                    <TextField
                        select
                        fullWidth
                        label="Catégorie"
                        name="category_id"
                        value={adDetails.category_id}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.category_id}
                        helperText={errors.category_id}
                        required
                    >
                        {categorieCategories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Etat"
                        name="etat_id"
                        value={adDetails.etat_id}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.etat_id}
                        helperText={errors.etat_id}
                        required
                    >
                        {etatCategories.map((etat) => (
                            <MenuItem key={etat.id} value={etat.id}>{etat.name}</MenuItem>
                        ))}Ò
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Taille"
                        name="size_id"
                        value={adDetails.size_id}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.size_id}
                        helperText={errors.size_id}
                        required
                    >
                        {tailleCategories.map((taille) => (
                            <MenuItem key={taille.id} value={taille.id}>{taille.name}</MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        multiline
                        rows={4}
                        value={adDetails.description}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.description}
                        helperText={errors.description}
                        required
                    />
                    <TextField
                        fullWidth
                        label="Prix"
                        name="price"
                        value={adDetails.price}
                        onChange={handleChange}
                        margin="normal"
                        error={!!errors.price}
                        helperText={errors.price}
                        required
                    />
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={handleImageChange}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                                Charger des images
                            </Button>
                        </label>
                        {adDetails.images.map((image, index) => (
                            <Box key={index} sx={{ position: 'relative', display: 'inline-flex', marginRight: 2, marginBottom: 2 }}>
                                <img src={image} alt={`Preview ${index}`} style={{ maxHeight: '100px' }} />
                                <IconButton
                                    size="small"
                                    sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                                    onClick={() => handleImageDelete(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                        Poster l'annonce
                    </Button>
                    {submitError && (
                        <Typography color="error" sx={{ mt: 1 }}>
                            {submitError}
                        </Typography>
                    )}
                </form>
            </Container>
            <Footer />
        </>
    );
}

export default CreateAd;
