import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Grid } from '@mui/material';

function AdList({ ads }) {
  return (
    <Grid container spacing={4}>
      {ads.map((ad) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={ad.id}>
          <Card sx={{ 
            maxWidth: 345, 
            margin: 1, 
                    padding: '1px', 
                    borderRadius: '10px', // Rounded corners
                    boxShadow: 3 ,// O
            //height: '100%', 
            display: 'flex', 
            flexDirection: 'column' }}>
            <CardActionArea component={RouterLink} to={`/ads/${ad.id}`}>
              {/* Display the first image from the array if available */}
              {ad.images && ad.images[0] && (
                <CardMedia
                  component="img"
                  height="140"
                  image={ad.images[0]} // Use the first image from the array
                  alt={ad.title}
                />
              )}
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="div">
                  {ad.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {ad.description}
                </Typography>
                <Typography variant="body2">
                  Taille: {ad.size_name}
                </Typography>
                <Typography variant="body2">
                  Etat: {ad.etat_name}
                </Typography>
                <Typography variant="body2">
                  Date de publication: {new Date(ad.created_at).toLocaleDateString()}
                </Typography>
                <Typography gutterBottom variant="h5" component="div">
                  {ad.price} €
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default AdList;
