import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Use useParams hook to get URL parameters
import { useNavigate,  Link as RouterLink } from 'react-router-dom';
import ChatBox from './ChatBox';
import {jwtDecode} from 'jwt-decode';
import { Container, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import NavBar from './NavBar'; // Adjust the path as necessary
import Footer from './Footer'; // Adjust the path as necessary
import { Button } from '@mui/material';

function AdDetail() {
    const [ad, setAd] = useState(null);
    const { id } = useParams(); // Get ad ID from URL params using useParams
    const [userId, setUserId] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [isPublisher, setIsPublisher] = useState(false); // State to track if the current user is the publisher
    const navigate = useNavigate();
    const token = localStorage.getItem('token');   
    useEffect(() => {
     
        if (token) {
            const decoded = jwtDecode(token);
            setUserId(decoded.userId); // Assuming the token payload has a userId field
        }

        const fetchAdAndChat = async () => {

            try {
                const adResponse = await axios.get(`${process.env.REACT_APP_API_DNS}/api/ads/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setAd(adResponse.data);
                console.log(adResponse.data)
               
                // Determine if the current user is the publisher of the ad

                // Fetch or create a chat for this ad and user
                if (userId && userId !== adResponse.data.user_id) {
                    const chatResponse = await axios.post(`${process.env.REACT_APP_API_DNS}/api/messages/chats`, { adId: id, userId }, { headers: { Authorization: `Bearer ${token}` } });
                    setChatId(chatResponse.data.chatId);
                }
                setIsPublisher(adResponse.data.user_id === userId);
                
            } catch (error) {
                console.error('Failed to fetch ad details or chat ID:', error);
            }
        };

        fetchAdAndChat();
    }, [id, userId]);
    
    const handleDelete = async (adId) => {
        if (window.confirm("Pouvez-vous confirmer que vous voulez supprimer l'annonce ?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_DNS}/api/ads/${adId}`, { headers: { Authorization: `Bearer ${token}` } });
                alert('Annonce supprimée avec succès');
                // Redirect or update the UI accordingly
            } catch (error) {
                console.error('Failed to delete ad:', error);
                alert('Failed to delete ad.');
            }
        }
    };

    if (!ad) return (
        <Container>
            <Typography>
                Il faut avoir un compte pour voir les annonces. Merci de{"   "}
                <RouterLink component={RouterLink} to="/login">
                    cliquer sur ce lien pour se connecter 
                </RouterLink>
                ou sur {"   "}
                <RouterLink component={RouterLink} to="/register">
                  ce lien pour s'enregister 
                </RouterLink>
            </Typography>
        </Container>
    );
    if (ad.archived === true) return (
        <Container>
            <Typography>
               Il semble que l'annonceur a supprimé cette annonce. Essayez de renter en contact avec lui via l outil conversationnel si vous aviez débuté une discussion
               {"   "}
                <RouterLink component={RouterLink} to="/">
                    <br></br>Retour a la page d'accueil
                </RouterLink>
              
            </Typography>
        </Container>
    );
    return (
        <div>
        <NavBar />
        <Container maxWidth="md">
            <Card sx={{ maxWidth: '100%', mt: 4 }}>
                {ad.images && ad.images.length > 0 && (
                    <CardMedia
                        component="img"
                        height="300" // Adjust height as needed
                        image={ad.images[0]} // Display the first image
                        alt={ad.title}
                    />
                )}
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {ad.title}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        {ad.description}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body2">Taille: {ad.size_name}</Typography>
                        <Typography variant="body2">Etat: {ad.etat_name}</Typography>
                        <Typography variant="body2">Date de publication: {new Date(ad.created_at).toLocaleDateString()}</Typography>
                        {ad.alias && (
                            <Typography variant="body2" color="text.secondary">
                                Publié par: {ad.alias}
                            </Typography>
                        )}
                        <Typography gutterBottom variant="" component="h3">
                        {ad.price} €
                    </Typography>
                    </Box>
                </CardContent>
            </Card>
            {isPublisher && (
            <Box sx={{ mt: 2 }}>
                <Button id="AdDetailEdit" variant="contained" color="primary" sx={{ mr: 1 }} onClick={() => navigate(`/edit-ad/${id}`)}>Editer</Button>
                <Button id="AdDetailDelete"variant="contained" color="secondary" onClick={() => handleDelete(ad.id)}>Supprimer</Button>
                <Typography gutterBottom variant="body1" component="p">
                        En tant qu'annonceur, vous pouvez éditer / supprimer votre annonce. Pour voir vos messages, merci d'accèder à vos conversation via la barre de navigation
                    </Typography>
            </Box> )}
          
           {!isPublisher && chatId && <ChatBox chatId={chatId} isPublisher={isPublisher} />} 
           
        </Container>
        <Footer />
        </div>
    );
}

export default AdDetail;
