import React from 'react';
import NavBar from './NavBar';  // Adjust the import path as necessary
import Footer from './Footer';  // Adjust the import path as necessary
import { Typography, Container } from '@mui/material';

const Terms = () => {
    return (
        <div>
            <NavBar />
            <Container component="main" maxWidth="md" style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Politique de confidentialité
                </Typography>
                <Typography variant="body1" paragraph>
                Le site web apelsalbris est détenu par APEL St Georges Salbris, qui est un contrôleur de données de vos données personnelles.
Nous avons adopté cette politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par apelsalbris, qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser le site web de apelsalbris.
Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.

                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                Les informations personnelles que nous collectons :
                </Typography>
                <Typography variant="body1" paragraph>
                Lorsque vous visitez le apelsalbris, nous recueillons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez sur le site, nous recueillons des informations sur les pages web ou les produits individuels que vous consultez, sur les sites web ou les termes de recherche qui vous ont renvoyé au site et sur la manière dont vous interagissez avec le site. Nous désignons ces informations collectées automatiquement par le terme "informations sur les appareils". En outre, nous collectons les données personnelles que vous nous fournissez (nom, prénom, adresse email, numéro de téléphone) lors de l'inscription.

                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                Pourquoi traitons-nous vos données ?
                </Typography>
                <Typography variant="body1" paragraph>
                Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour maintenir le site web. Les informations collectées automatiquement sont utilisées uniquement pour identifier les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation du site web. Ces informations statistiques ne sont pas autrement agrégées de manière à identifier un utilisateur particulier du système.
Vous pouvez visiter le site web sans nous dire qui vous êtes ni révéler d'informations, par lesquelles quelqu'un pourrait vous identifier comme un individu spécifique et identifiable. Toutefois, si vous souhaitez utiliser certaines fonctionnalités du site web et notamment pouvoir proposer des vêtements à la vente, échanger avec de potentiels acheteurs ou échanger avec des vendeurs en tant qu’acheteur vous devez nous fournir des données personnelles, telles que votre e-mail, votre prénom, votre nom et votre numéro de téléphone. Vous pouvez choisir de ne pas nous fournir vos données personnelles, mais il se peut alors que vous ne puissiez pas profiter de certaines fonctionnalités du site web. Les utilisateurs qui ne savent pas quelles informations sont obligatoires sont invités à nous contacter via apelsaintgeorgessalbris@hotmail.com. 

                </Typography>

                <Typography variant="h5" component="h1" gutterBottom>
                Vos droits :

                </Typography>
                <Typography variant="body1" paragraph>
                Si vous êtes un résident européen, vous disposez des droits suivants liés à vos données personnelles :
    <ul>
    <li>   Le droit d'être informé.     </li>
    <li>     Le droit d'accès.   </li>
    <li>     Le droit de rectification.   </li>
    <li>    Le droit à l'effacement.    </li>
    <li>    Le droit de restreindre le traitement.    </li>
    <li>    Le droit à la portabilité des données.    </li>
    <li>   Le droit d'opposition.     </li>   
    <li> Les droits relatifs à la prise de décision automatisée et au profilage. </li>
    </ul>
    
Si vous souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous.
En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous pourrions avoir avec vous (par exemple, si vous passez une commande par le biais du site), ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que vos informations pourraient être transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.

                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                Liens vers d'autres sites web :

                </Typography>
                <Typography variant="body1" paragraph>
                Notre site web peut contenir des liens vers d'autres sites web qui ne sont pas détenus ou contrôlés par nous. Sachez que nous ne sommes pas responsables de ces autres sites web ou des pratiques de confidentialité des tiers. Nous vous encourageons à être attentif lorsque vous quittez notre site web et à lire les déclarations de confidentialité de chaque site web susceptible de collecter des informations personnelles.
                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                Sécurité de l'information :
                </Typography>
                <Typography variant="body1" paragraph>
                Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé, protégé contre tout accès, utilisation ou divulgation non autorisés. Nous conservons des garanties administratives, techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation non autorisés des données personnelles sous son contrôle et sa garde. Toutefois, aucune transmission de données sur Internet ou sur un réseau sans fil ne peut être garantie.

                </Typography>
                <Typography variant="h5" component="h1" gutterBottom>
                Divulgation légale :
                </Typography>
                <Typography variant="body1" paragraph>
                Nous divulguerons toute information que nous collectons, utilisons ou recevons si la loi l'exige ou l'autorise, par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.
                </Typography>


                <Typography variant="h5" component="h1" gutterBottom>
                Informations de contact :
                </Typography>
                <Typography variant="body1" paragraph>
                Si vous souhaitez nous contacter pour comprendre davantage la présente politique ou si vous souhaitez nous contacter concernant toute question relative aux droits individuels et à vos informations personnelles, vous pouvez envoyer un courriel à apelsaintgeorgessalbris@hotmail.com.
                </Typography>




            </Container>
            <Footer />
        </div>
    );
};

export default Terms;
