import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme you defined
import Homepage from './components/Homepage';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import AdDetail from './components/AdDetail';
import CreateAd from './components/CreateAd';
import PublisherMessages2 from './components/PublisherMessage2';
import isTokenValid from './components/isTokenValid';
import PasswordReset from './components/PasswordReset';
import ForgotPassword from './components/ForgotPassword';
import Terms from './components/Terms';
import Admin from './components/Admin';
import UserAdmin from './components/UserAdmin';
import EditAd from './components/EditAd';

const ProtectedRoute = ({ component: Component }) => {
  const isValid = isTokenValid();
  return isValid ? <Component /> : <Navigate to="/login" />;
};

function App() {
  return (
    <ThemeProvider theme={theme}>
  <Router>
    <Routes>
      
      <Route path="/"  element={<Homepage />} /> 
      <Route path="/footer"  element={<Footer />} />
      <Route path="/login"  element={<Login />} />
      <Route path="/register"  element={<Register />} />
      <Route path="/ads/:id" element={<AdDetail />} /> 
      <Route path="/edit-ad/:id" element={<EditAd />} /> 
      <Route path="/forgot-password" element={<ForgotPassword />} /> 
      <Route path="/reset-password/:resetCode" element={<PasswordReset />} />
      <Route path="/publisher-messages2" element={<PublisherMessages2 />} /> 
      <Route path="/terms" element={<Terms />} /> 
      <Route path="/admin" element={<Admin />} />
      <Route path="/useradmin" element={<UserAdmin />} />
      <Route
          path="/create-ad"
          element={
            <ProtectedRoute component={CreateAd} /> // Use ProtectedRoute for routes that require authentication
          }
        />
    </Routes>
  </Router> 
  </ThemeProvider>
  );
}

export default App;
