import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, TextField, Button, List, ListItem, ListItemText, Typography } from '@mui/material';
import UnreadIcon from '@mui/icons-material/Markunread'; // Example icon, adjust as needed
import { useUser } from './UserContext';

function ChatBox({ chatId, isPublisher }) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]); // State to store chat history
    const { setTotalUnread } = useUser();  // Using useUser to access setTotalUnread

    useEffect(() => {
        fetchMessages();
        fetchUnreadCount();
    }, [chatId]);

    const fetchMessages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/chats/${chatId}`);
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchUnreadCount = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/unread/user`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data && response.data.count !== undefined) {
                setTotalUnread(response.data.count);
            }
        } catch (error) {
            console.error('Failed to fetch unread messages count:', error);
        }
    };

    const sendMessage = async () => {
        if (!message) return;
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_API_DNS}/api/messages`, { chat_id: chatId, content: message }, { headers: { Authorization: `Bearer ${token}` } });
            setMessage('');
            const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/messages/chats/${chatId}`);
            setMessages(response.data);
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleMessageRead = async (messageId) => {
        // Call to backend to update read status
        const token = localStorage.getItem('token');
        await axios.patch(`${process.env.REACT_APP_API_DNS}/api/messages/${messageId}/read`, { isPublisher }, { headers: { Authorization: `Bearer ${token}` } });
    };

    return (
        <Box sx={{ p: 3, width: '100%' }}>
            <Typography variant="h6">Discussion par messages</Typography>
            <List sx={{ display: 'flex', flexDirection: 'column', p: 3 }}>
                {messages.map((msg) => (
                    <ListItem
                        key={msg.id}
                        sx={{
                            alignSelf: !msg.ispublisher ? 'flex-end' : 'flex-start',
                            bgcolor: !msg.ispublisher ? '#f0f0f0' : '#e0e0e0',
                            p: 1,
                            borderRadius: 2,
                            mb: 1,
                            maxWidth: '75%',
                            boxShadow: 1,
                        }}
                    >
                        <ListItemText
                            primary={msg.content}
                            secondary={`${!msg.ispublisher ? 'envoyé' : 'reçu'}: ${new Date(msg.created_at).toLocaleString()}`}
                            sx={{ textAlign: !msg.ispublisher ? 'right' : 'left', maxWidth: '100%' }}
                        />
                        {!msg.publisher_read && isPublisher && (
                            <UnreadIcon onClick={() => handleMessageRead(msg.id)} />
                        )}
                        {!msg.client_read && !isPublisher && (
                            <UnreadIcon onClick={() => handleMessageRead(msg.id)} />
                        )}
                    </ListItem>
                ))}
            </List>
            <TextField id="WriteaMessage"
                fullWidth
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message here..."
                variant="outlined"
                sx={{ mt: 1 }}
            />
            <Button id="ChatSendMessage" variant="contained" color="primary" onClick={sendMessage} sx={{ mt: 1 }}>
                Envoyer
            </Button>
        </Box>
    );
}

export default ChatBox;
