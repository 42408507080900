import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, IconButton, MenuItem } from '@mui/material';
import NavBar from './NavBar';
import Footer from './Footer';
import {jwtDecode} from 'jwt-decode';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Compressor from 'compressorjs';

function EditAd() {
    const [userId, setUserId] = useState(null);
    const [ad, setAd] = useState({
        title: '',
        description: '',
        size_id: '',
        etat_id: '',
        category_id: '',
        images: [],  // Assuming images are handled as an array of URLs or data
        price: ''
    });
    const { id } = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]); // State to hold categories
    const [etatCategories, setEtatCategories] = useState([]);
    const [tailleCategories, setTailleCategories] = useState([]);
    const [categorieCategories, setCategorieCategories] = useState([]);
    const token = localStorage.getItem('token');   

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setUserId(decoded.userId); // Assuming the token payload has a userId field
        }

        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/categories`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const categories = response.data;
                setCategories(categories);

                const etat = categories.filter(category => category.typology === 'etat');
                const taille = categories.filter(category => category.typology === 'taille');
                const categorie = categories.filter(category => category.typology === 'categorie');

                setEtatCategories(etat);
                setTailleCategories(taille);
                setCategorieCategories(categorie);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
                alert('Failed to fetch categories.');
            }
        };

        const fetchAdDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_DNS}/api/ads/${id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setAd({
                    ...response.data,
                    category_id: response.data.category_id, // Assuming your ad object uses category_id
                    etat_id: response.data.etat_id, // Assuming your ad object uses etat_id
                    size_id: response.data.size_id // Assuming your ad object uses size_id
                });
            } catch (error) {
                console.error('Failed to fetch ad details:', error);
                alert('Failed to fetch ad details.');
            }
        };

        fetchCategories();
        fetchAdDetails();
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAd({...ad, [name]: value});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_API_DNS}/api/ads/${id}`, {
                ...ad,
                category_id: parseInt(ad.category_id),
                etat_id: parseInt(ad.etat_id),
                size_id: parseInt(ad.size_id)
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            alert('Ad updated successfully');
            navigate(`/ads/${id}`); // Redirect to the ad detail page after update
        } catch (error) {
            console.error('Failed to update ad:', error);
            alert('Failed to update ad.');
        }
    };

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            new Compressor(file, {
                quality: 0.9, // Set the quality to 90% of the original
                maxWidth: 800, // Set the maximum width to 800px
                maxHeight: 600, // Set the maximum height to 600px
                success(result) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setAd({ ...ad, images: [...ad.images, e.target.result] }); // Add the compressed and converted Base64 string to the images array
                    };
                    reader.readAsDataURL(result); // Convert the compressed image file to a Data URL
                },
                error(err) {
                    console.error('Compression Error:', err.message);
                },
            });
        }
    };

    const handleImageDelete = (index) => {
        const updatedImages = ad.images.filter((_, i) => i !== index);
        setAd({ ...ad, images: updatedImages });
    };

    return (
        <>
        <NavBar />
        <Container component="main" maxWidth="md">
            <Typography component="h1" variant="h5" sx={{ mt: 3, mb: 2 }}>
                Editer votre annonce
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Titre de l'annonce"
                    name="title"
                    value={ad.title}
                    onChange={handleChange}
                    margin="normal"
                />
                {/* Category ID, assuming you have a method to fetch and list categories */}
                <TextField
                    select
                    fullWidth
                    label="Catégorie"
                    name="category_id"
                    value={ad.category_id}
                    onChange={handleChange}
                    margin="normal"
                >
                    {categorieCategories.map((category) => (
                        <MenuItem key={category.id} value={category.id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    fullWidth
                    label="Etat"
                    name="etat_id"
                    value={ad.etat_id}
                    onChange={handleChange}
                    margin="normal"
                >
                    {etatCategories.map((etat) => (
                        <MenuItem key={etat.id} value={etat.id}>
                            {etat.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    fullWidth
                    label="Taille"
                    name="size_id"
                    value={ad.size_id}
                    onChange={handleChange}
                    margin="normal"
                >
                    {tailleCategories.map((taille) => (
                        <MenuItem key={taille.id} value={taille.id}>
                            {taille.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                    value={ad.description}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    fullWidth
                    label="Prix"
                    name="price"
                    value={ad.price}
                    onChange={handleChange}
                    margin="normal"
                />
                {/* Image Upload */}
                <Box sx={{ mt: 2, mb: 2 }}>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleImageChange}
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" startIcon={<PhotoCamera />}>
                            Charger une image
                        </Button>
                    </label>
                    {ad.images.map((image, index) => (
                        <Box key={index} sx={{ position: 'relative', display: 'inline-flex', marginRight: 2, marginBottom: 2 }}>
                            <img src={image} alt={`Preview ${index}`} style={{ maxHeight: '100px' }} />
                            <IconButton 
                                size="small" 
                                sx={{ position: 'absolute', top: 0, right: 0, color: 'red' }}
                                onClick={() => handleImageDelete(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
                    Poster l'annonce
                </Button>
            </form>
        </Container>
        <Footer />
        </>
    );
}

export default EditAd;
