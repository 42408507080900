import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography } from '@mui/material';
import NavBar from './NavBar'; // Adjust the path as necessary
import Footer from './Footer'; // Adjust the path as necessary

function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nickName, setNickName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_DNS}/api/users/register`, {
                firstName, // Make sure the backend is expecting "firstName" and not "first_name"
                lastName,  // Similarly, ensure the backend expects "lastName"
                email,
                password,
                nickName
            });
            localStorage.setItem('token', response.data.token);
            alert('Enregistrement fait!');
            navigate('/'); // Redirect to homepage or login page as needed
        } catch (error) {
            console.error('Echec', error.response ? error.response.data : error);
        }
    };

    return (
        <div>
            <NavBar />
            <Container component="main" maxWidth="xs">
                <Typography component="h1" variant="h5">S'enregistrer</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Prénom"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Nom de famille"
                        name="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Pseudo"
                        name="nickName"
                        value={nickName}
                        onChange={(e) => setNickName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Mot de passe"
                        name="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        S'enregistrer
                    </Button>
                </form>
            </Container>
            <Footer />
        </div>
    );
}

export default Register;
