import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, TextField, Button, MenuItem, Typography, InputAdornment, BottomNavigation, BottomNavigationAction, useMediaQuery, Modal
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';

function FilterSidebar({ onFilterChange }) {
  const [categories, setCategories] = useState([]);
  const [etatCategories, setEtatCategories] = useState([]);
  const [tailleCategories, setTailleCategories] = useState([]);
  const [categorieCategories, setCategorieCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTaille, setSelectedTaille] = useState('');
  const [selectedEtat, setSelectedEtat] = useState('');
  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000 });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Fetch categories from the backend
    axios.get(`${process.env.REACT_APP_API_DNS}/api/categories`).then((response) => {
      const categories = response.data;
      setCategories(categories);

      const etat = categories.filter(category => category.typology === 'etat');
      const taille = categories.filter(category => category.typology === 'taille');
      const categorie = categories.filter(category => category.typology === 'categorie');

      setEtatCategories(etat);
      setTailleCategories(taille);
      setCategorieCategories(categorie);
    });
  }, []);

  const handleFilterChange = () => {
    onFilterChange({
      category_id: selectedCategory,
      priceRange,
      size_id: selectedTaille,
      etat_id: selectedEtat,
    });
    if (isMobile) {
      setIsModalOpen(false); // Close modal on filter apply
    }
  };

  const filterForm = (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>Filtres</Typography>

      {/* Category Filter */}
      <TextField
        select
        fullWidth
        label="Catégorie"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {categorieCategories.map((category) => (
          <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
        ))}
      </TextField>

      {/* Price Filter */}
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
        <TextField
          label="Prix min"
          type="number"
          value={priceRange.min}
          onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
        <TextField
          label="Prix max"
          type="number"
          value={priceRange.max}
          onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </Box>

      {/* Size Filter */}
      <TextField
        select
        fullWidth
        label="Taille"
        value={selectedTaille}
        onChange={(e) => setSelectedTaille(e.target.value)}
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {tailleCategories.map((taille) => (
          <MenuItem key={taille.id} value={taille.id}>{taille.name}</MenuItem>
        ))}
      </TextField>

      {/* Etat Filter */}
      <TextField
        select
        fullWidth
        label="Etat"
        value={selectedEtat}
        onChange={(e) => setSelectedEtat(e.target.value)}
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {etatCategories.map((etat) => (
          <MenuItem key={etat.id} value={etat.id}>{etat.name}</MenuItem>
        ))}
      </TextField>

      <Button variant="contained" onClick={handleFilterChange} sx={{ mt: 2 }}>
        Appliquer les filtres
      </Button>
    </Box>
  );

  return isMobile ? (
    <>
      <BottomNavigation
        showLabels
        onChange={(event, newValue) => {
          if (newValue === 'filter') {
            setIsModalOpen(true);
          }
        }}
      >
        <BottomNavigationAction label="Filters" value="filter" icon={<FilterListIcon />} />
      </BottomNavigation>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-filters-title"
        aria-describedby="modal-filters-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          {filterForm}
        </Box>
      </Modal>
    </>
  ) : (
    <Box sx={{ width: 250, p: 2, borderRight: 1, borderColor: 'divider' }}>
      {filterForm}
    </Box>
  );
}

export default FilterSidebar;
